
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { ClassActions } from "@/store/vm/class/enums";
import { StepResult } from "@/store/captain/CaptainModule";
// import Multiselect from "@vueform/multiselect";
import router from "@/router";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import CourseSelector from "@/views/course/selector/Course.vue";
import { ClassPublicStatus, ClassStatus } from "@/store/vm/class/ClassModule";
import { convertDateStrYYYYMMDDToDate } from "@/core/helpers/common";

export default defineComponent({
  name: "upsert-class",
  components: {
    CourseSelector,
  },
  props: {
    classId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    // Get params from url
    const classId =
      route.params["classId"] === "create" ? false : route.params["classId"];

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const defaultData = {
      class_id: 0,
      course_id: 0,
      code: "",
      name: "",
      is_public: ClassPublicStatus.PUBLIC,
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Khóa học video", ["Đào tạo"]);
      MenuComponent.reinitialization();

      if (classId) {
        const detail = await store
          .dispatch(ClassActions.GET_DETAIL, classId)
          .catch((e) => {
            console.log(e);
          });

        formData.value = { ...detail };
      }
    });

    const formData = ref({ ...defaultData });

    const isPublicLabels = [
      {
        value: 0,
        label: "Chưa công khai",
      },
      {
        value: 1,
        label: "Công khai (hiển thị trên App)",
      },
    ];

    const rules = ref({
      name: [
        {
          required: true,
          message: "Yêu cầu nhập tên khóa học",
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          message: "Yêu cầu mã khóa học",
          trigger: "change",
        },
      ],
    });

    const isCreate = !classId;

    const actionCreate = (par) => {
      return store.dispatch(ClassActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(ClassActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const onChange = (payload) => {
      const name = payload.name;
      const value = payload.value;
      formData.value[name] = value;
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;

          // reserve transform
          const data = { ...formData.value };

          if (isCreate) {
            stepResult = await actionCreate(data);
          } else {
            stepResult = await actionUpdate(data);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              router.push({ name: "ClassVideo" });
            });
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    return {
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      isPublicLabels,
      onChange,
    };
  },
});
